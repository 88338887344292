@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrFJA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7V1s.ttf) format('truetype');
}
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: #777;
}
::-webkit-scrollbar-thumb {
  background: #D42426;
}
::-webkit-scrollbar-thumb:hover {
  background: #D42426;
}
body {
  margin: 0;
}
img {
  max-width: 100%;
}
.body-site-container {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  background: #fff;
  cursor: default;
}
.header {
  position: fixed;
  width: 100%;
  z-index: 9999;
}
@media screen and (min-width: 768px) {
  .header .main_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
    background: #fff;
  }
}
@media screen and (max-width: 767px) {
  .header .main_menu {
    display: none;
  }
}
.header .main_menu .logo_container .logo {
  text-decoration: none;
}
.header .main_menu .logo_container .logo img {
  max-width: 100px;
}
.header .main_menu .list_menu {
  display: flex;
  align-items: center;
  margin: 0;
}
.header .main_menu .list_menu .active {
  color: #D42426;
  border-bottom: 2px solid #D42426;
  font-weight: 700;
}
.header .main_menu .list_menu a {
  text-decoration: none;
  background: none;
  border: none;
  color: #000;
  outline: 0;
  font-size: 14px;
  letter-spacing: 0.1em;
  padding: 0.75em 1em;
}
@media screen and (min-width: 768px) {
  .header .menu-mobile {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .header .menu-mobile {
    flex: 0 0 45%;
    text-align: center;
    color: #000;
    background: #fff;
    padding-top: 1em;
  }
}
.header .menu-mobile .contenedor-menu {
  text-align: center;
  padding-bottom: 1em;
}
.header .menu-mobile .contenedor-menu .logo_container {
  margin-bottom: 1em;
}
.header .menu-mobile .contenedor-menu .logo_container .logo {
  text-decoration: none;
}
.header .menu-mobile .contenedor-menu .logo_container .logo img {
  max-width: 100px;
}
.header .menu-mobile .contenedor-menu .select {
  position: relative;
  display: flex;
  width: 14em;
  height: 3em;
  line-height: 3;
  background: #D42426;
  overflow: hidden;
  margin: 0 auto;
}
.header .menu-mobile .contenedor-menu .select:after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #D42426;
  color: #fff;
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
}
.header .menu-mobile .contenedor-menu .select select {
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #D42426;
  flex: 1;
  padding: 0 0.5em;
  color: #fff;
  cursor: pointer;
}
.header .menu-mobile .contenedor-menu .select select::-ms-expand {
  display: none;
}
footer {
  background: #D42426;
  padding: 1.5em;
  margin-top: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer p {
  color: #fff;
  font-weight: 700;
  margin: 0;
}
footer a {
  color: #fff;
  text-decoration: none;
}
footer a:hover,
footer a:focus {
  color: #fff;
}
.title_section {
  text-align: center;
  max-width: 90%;
  margin: 3em auto;
}
.title_section h2 {
  font-weight: 700;
  color: #000;
  position: relative;
  display: table;
  margin: 0.5em auto;
  padding-bottom: 0.1em;
  border-bottom: 5px solid #D42426;
}
@media screen and (min-width: 768px) {
  .title_section h2 {
    font-size: 40px;
  }
}
@media screen and (max-width: 767px) {
  .title_section h2 {
    font-size: 30px;
  }
}
.section {
  margin-bottom: 5em;
  padding: 0 1em;
}
.section .content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .section .content .column {
    flex: 0 0 45%;
  }
}
@media screen and (max-width: 767px) {
  .section .content .column {
    flex: 0 0 100%;
    text-align: center;
  }
}
.section .content .column p {
  text-align: justify;
}
.section .content .column ul {
  list-style-type: square;
}
.section .content .column h3 {
  font-weight: 700;
  margin-top: 1em;
}
@media screen and (min-width: 768px) {
  .section .content .column h3 {
    font-size: 28px;
  }
}
@media screen and (max-width: 767px) {
  .section .content .column h3 {
    font-size: 21px;
  }
}
.section .content .column .payment {
  text-align: center;
  font-weight: 700;
}
.section .content .column .button {
  background: #D42426;
  color: #fff;
  border: 0;
  cursor: pointer;
  padding: 1.5em;
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .section .content .column .button {
    margin-bottom: 3em;
  }
}
.section .content .column-full {
  flex: 0 0 100%;
}
.section .content .column-full p {
  text-align: justify;
}
.section .content .column-full .activities {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .section .content .column-full .activities .activity {
    flex: 0 0 30%;
  }
}
@media screen and (max-width: 767px) {
  .section .content .column-full .activities .activity {
    flex: 0 0 100%;
  }
}
.section .content .column-full .activities .activity h3 {
  color: #D42426;
  font-size: 24px;
}
@media screen and (min-width: 768px) {
  .home-container {
    padding-top: 9em;
  }
}
@media screen and (max-width: 767px) {
  .home-container {
    padding-top: 13em;
  }
}
.home-container .slider {
  height: 500px;
  margin-bottom: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
}
@media screen and (min-width: 768px) {
  .foundation-container {
    padding-top: 3em;
  }
}
@media screen and (max-width: 767px) {
  .foundation-container {
    padding-top: 13em;
  }
}
@media screen and (min-width: 768px) {
  .families-container {
    padding-top: 9em;
  }
}
@media screen and (max-width: 767px) {
  .families-container {
    padding-top: 13em;
  }
}
.families-container .form-sponsor-container {
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 5em;
}
.families-container .form-sponsor-container label {
  font-size: 16px;
  font-weight: 700;
}
.families-container .form-sponsor-container input[type="text"],
.families-container .form-sponsor-container input[type="email"] {
  padding: 4px 15px;
  height: 50px;
  border-radius: 0;
  margin-bottom: 1em;
}
.families-container .form-sponsor-container button {
  width: 100%;
  line-height: 50px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fff;
  background: #D42426;
  border: none;
}
.families-container #login-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (min-width: 768px) {
  .families-container #login-container .form-container {
    flex: 0 0 45%;
  }
}
@media screen and (max-width: 767px) {
  .families-container #login-container .form-container {
    flex: 0 0 90%;
  }
}
.families-container #login-container .form-container .login-btn {
  color: #fff;
  padding: 1em;
  font-weight: 700;
  cursor: pointer;
  margin: 2em auto;
  width: 90%;
  text-align: center;
}
.families-container #login-container .form-container .google {
  background: #DD492F;
}
@media screen and (min-width: 768px) {
  .families-container #login-container .photo-container {
    flex: 0 0 45%;
  }
}
@media screen and (max-width: 767px) {
  .families-container #login-container .photo-container {
    flex: 0 0 90%;
  }
}
.families-container .show-container {
  display: block;
}
.families-container .hide-container {
  display: none !important;
}
.families-container .table_container {
  max-width: 90%;
  margin: 1em auto;
}
.families-container .table_container table {
  table-layout: fixed;
}
.families-container .table_container table thead tr {
  border-bottom: 3px solid #000;
}
.families-container .table_container table thead tr th {
  font-weight: 600;
  color: #000;
  text-align: center;
  padding: 22px 0;
}
.families-container .table_container table tbody tr:nth-of-type(even) {
  background: #777;
  color: #fff;
}
.families-container .table_container table tbody tr td {
  padding: 10px;
  text-align: center;
}
.families-container .table_container table tbody tr td .adopted {
  background: green;
  color: #fff;
  padding: 0.5em;
  width: 100px;
  display: block;
  margin: 0 auto;
}
.families-container .table_container table tbody tr td .btn_adopt {
  background: #D42426;
  color: #fff;
  padding: 0.5em;
  border: 0;
  width: 100px;
}
@media screen and (min-width: 768px) {
  .videos-container {
    padding-top: 9em;
  }
}
@media screen and (max-width: 767px) {
  .videos-container {
    padding-top: 13em;
  }
}
.videos-container .capsules-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
